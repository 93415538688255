<template>
	<DefaultLayout>
		<div class="max-w-3xl p-4 mx-auto">
			<OrderDetail
				v-if="order.id"
				:order="order"
			/>
		</div>
	</DefaultLayout>
</template>

<script async>
import OrderDetail from '@/components/order/OrderDetail.vue'
import DefaultLayout from '@/layouts/default/Index.vue'
import tempModule from '@/store/modules/tempModule.js'

const HOURS_UNTIL_CACHE_INVALIDATED = 6

export default {
	components: {
		OrderDetail,
		DefaultLayout
	},
	data() {
		return {
			order: {}
		}
	},
	beforeCreate() {
		this.$store.registerModule('temp', tempModule)
	},
	mounted() {
		this.handleCaching()
		if (!this.order.id) {
			this.$router.push('/')
		}
	},
	methods: {
		cacheOrder() {
			localStorage.setItem('order', JSON.stringify(this.order))
			localStorage.setItem('cache-timestamp', JSON.stringify(new Date()))
		},
		isExpiredCache(timestampString) {
			const now = new Date()
			const timestamp = new Date(timestampString)
			const expirationTime = timestamp.setHours(
				timestamp.getHours() + HOURS_UNTIL_CACHE_INVALIDATED
			)

			return now > expirationTime
		},
		clearCache() {
			localStorage.removeItem('order')
			localStorage.removeItem('cache-timestamp')
		},
		getCachedOrder() {
			const cachedOrder = JSON.parse(localStorage.getItem('order'))
			const timestamp = JSON.parse(localStorage.getItem('cache-timestamp'))
			if (!timestamp || this.isExpiredCache(timestamp)) {
				this.clearCache()
				return
			}
			return cachedOrder
		},
		handleCaching() {
			this.order = this.$store.state.temp?.order || {}
			const cachedOrder = this.getCachedOrder()

			if (this.order.id) {
				this.cacheOrder()
			}
			if (!this.order.id && cachedOrder) {
				this.order = cachedOrder
			}
		}
	}
}
</script>
